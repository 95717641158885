import Vue from 'vue'

import App from './App.vue'
import BrowserSupport from './components/BrowserSupport.vue'
import fileDirective from './directives/file.directive'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import { router } from './router'
import store from './store'
import * as actions from './store/actions/types'

Vue.config.productionTip = false
Vue.directive('file', fileDirective)

window.addEventListener('DOMContentLoaded', function initApp () {
  window.removeEventListener('DOMContentLoaded', initApp)

  const isBrowserSupported = (
    'fetch' in window &&
    'MutationObserver' in window &&
    'FormData' in window
  )

  if (!isBrowserSupported) {
    document.body.dataset.loading = false

    return new Vue({
      vuetify,
      i18n,
      render: h => h(BrowserSupport)
    }).$mount('#app')
  }

  (async function () {
    await store.dispatch(actions.GET_ACCOUNT)
    document.body.dataset.loading = false

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })()

  window.addEventListener('storage', evt => {
    if (evt.key !== 'account') { return }
    location.reload()
  })
})
