import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { getCookie } from '../utils/common'
import { request } from '../utils/request'
Vue.use(VueI18n)

const i18n = new VueI18n()
const loadedLanguages = []
const lang = getCookie('lang') || 'ru'
loadLanguageAsync(lang)

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export async function loadLanguageAsync (lang) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  try {
    request(`/account?lang=${lang}`)
    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    const dictinary = await import(/* webpackChunkName: "i18n/[request]" */ `./${lang}.js`)
    i18n.setLocaleMessage(lang, await dictinary.default())
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export default i18n
