import * as types from './types'

const mutateNow = (state, now = Date.now()) => {
  state.now = now
}

const mutateAccount = (state, account) => {
  state.account = account
}

const mutateVendor = (state, vendor) => {
  state.vendor = vendor
}

const mutateUsername = (state, username) => {
  state.username = username
}

const mutateNotification = (state, notification = null) => {
  state.notification = notification
}

const mutateCountNewMessages = (state, val = 0) => {
  state.countNewMessages = val
}

const mutateSessions = (state, sessions) => {
  state.sessions = sessions
}

const mutateSessionsItem = (state, session = {}) => {
  const idx = state.sessions.findIndex(s => s.order_id === session.order_id)
  if (idx !== -1) {
    state.sessions.splice(idx, 1, session)
  }
}

const mutateSession = (state, session = {}) => {
  state.session = session
}

const mutateStep = (state, step = {}) => {
  state.step = step
}

const mutatePermissions = (state, permissions = {}) => {
  state.permissions = permissions
}

const mutateQuiz = (state, quiz) => {
  state.quiz = quiz
}

const saveQuizQuestion = (state, { question = {}, userAnswers = [] }) => {
  const cloneQuiz = Object.assign({}, state.quiz)

  if (question && question.quiz_id === cloneQuiz.id) {
    const id = question.question_id
    const q = (cloneQuiz.questions || []).find(q => q.question_id === id)
    Object.assign(q, question)

    const answers = userAnswers.map(aid => {
      return question.answers.find(answer => answer.answer_id === aid)
    })

    cloneQuiz.user_answers = Object.assign({}, cloneQuiz.user_answers, {
      [id]: answers
    })

    state.quiz = cloneQuiz
  }
}

const mutateEssay = (state, essay) => {
  state.essay = essay
}

const mutateMaxFilesize = (state, maxFileSize) => {
  state.maxFilesize = maxFileSize
}

const mutateLecture = (state, lecture) => {
  state.lecture = lecture
}

const mutateLesson = (state, lesson) => {
  state.lesson = lesson
}

const mutateToggleSteps = (state, payload) => {
  state.isOpenSteps = payload
}

const mutateToggleView = (state, payload) => {
  state.isFullView = payload
}

const mutateMeetings = (state, meetings) => {
  state.meetings = meetings
}

export default {
  [types.MUTATE_NOW]: mutateNow,
  [types.MUTATE_ACCOUNT]: mutateAccount,
  [types.MUTATE_VENDOR]: mutateVendor,
  [types.MUTATE_USERNAME]: mutateUsername,
  [types.MUTATE_NOTIFICATION]: mutateNotification,
  [types.MUTATE_COUNT_NEW_MESSAGES]: mutateCountNewMessages,
  [types.MUTATE_SESSIONS]: mutateSessions,
  [types.MUTATE_SESSIONS_ITEM]: mutateSessionsItem,
  [types.MUTATE_SESSION]: mutateSession,
  [types.MUTATE_STEP]: mutateStep,
  [types.MUTATE_PERMISSIONS]: mutatePermissions,
  [types.MUTATE_QUIZ]: mutateQuiz,
  [types.SAVE_QUIZ_QUESTION]: saveQuizQuestion,
  [types.MUTATE_ESSAY]: mutateEssay,
  [types.MUTATE_MAX_FILESIZE]: mutateMaxFilesize,
  [types.MUTATE_LECTURE]: mutateLecture,
  [types.MUTATE_LESSON]: mutateLesson,
  [types.MUTATE_TOGGLE_STEPS]: mutateToggleSteps,
  [types.MUTATE_TOGGLE_VIEW]: mutateToggleView,
  [types.MUTATE_MEETINGS]: mutateMeetings
}
