import * as types from './types'

const now = (state) => new Date(state.now)

const username = (state) => state.username

const account = (state) => state.account

const vendor = (state) => state.vendor

const notification = (state) => state.notification

const countNewMessages = (state) => state.countNewMessages

const sessions = (state) => state.sessions

const countNewSessions = (state) => state.sessions.filter(
  session => !session.is_started
).length

const session = (state) => state.session

const step = (state) => state.step

const lesson = (state) => state.lesson

const essay = (state) => state.essay

const quiz = (state) => state.quiz

const questions = (state) => state.quiz?.questions || []

const lecture = (state) => state.lecture

const maxFilesize = (state) => state.maxFilesize || 0

const permissions = (state) => state.permissions

const isOpenSteps = (state) => state.isOpenSteps

const isFullView = (state) => state.isFullView

const meetings = (state) => state.meetings

const countNewMeetings = (state, getters) => state.meetings.filter(
  (meeting) => new Date(meeting.starts_at) > getters[types.NOW]
).length

export default {
  [types.NOW]: now,
  [types.USERNAME]: username,
  [types.ACCOUNT]: account,
  [types.VENDOR]: vendor,
  [types.NOTIFICATION]: notification,
  [types.COUNT_NEW_MESSAGES]: countNewMessages,
  [types.SESSIONS]: sessions,
  [types.COUNT_NEW_SESSIONS]: countNewSessions,
  [types.SESSION]: session,
  [types.STEP]: step,
  [types.LESSON]: lesson,
  [types.ESSAY]: essay,
  [types.QUIZ]: quiz,
  [types.QUESTIONS]: questions,
  [types.LECTURE]: lecture,
  [types.MAX_FILE_SIZE]: maxFilesize,
  [types.PERMISSIONS]: permissions,
  [types.IS_OPEN_STEPS]: isOpenSteps,
  [types.IS_FULL_VIEW]: isFullView,
  [types.MEETINGS]: meetings,
  [types.COUNT_NEW_MEETINGS]: countNewMeetings
}
