<template>
  <Fragment>
    <VNavigationDrawer
      class="main-menu"
      app
      clipped
      floating
      width="100"
    >
      <VBtn
        v-if="account.profile.is_default_listener"
        @click="dialog=true"
      >
        <VIcon>$uchi_orders</VIcon>
        {{ $t('order_management.one') }}
      </VBtn>
      <template v-for="menuItem in menuItems">
        <VBtn
          :key="menuItem.name"
          :to="menuItem.to"
          :href="menuItem.href"
        >
          <VBadge
            :value="menuItem.counter"
            :content="menuItem.counter"
            overlap
          >
            <VIcon>{{ menuItem.icon }}</VIcon>
          </VBadge>
          {{ $t(menuItem.title) }}
        </VBtn>
      </template>
    </VNavigationDrawer>

    <VAppBar
      app
      class="app-bar"
      clipped-left
      elevation="1"
    >
      <RouterLink
        :to="{ name: 'sessions' }"
        class="app-bar__logo"
        :title="vendor.title"
      >
        <VImg
          :src="(vendor.branding && vendor.branding.logo) ? `/${ vendor.branding.logo }` : logotype"
          :alt="vendor.title"
          contain
        />
      </RouterLink>

      <VToolbarTitle class="text-md-center flex-grow-1">
        <RouterLink :to="{ name: 'sessions' }">
          {{ vendor.title }}
        </RouterLink>
      </VToolbarTitle>

      <VBtn
        v-if="isDevelopment"
        icon
        title="Settings"
        @click="settings = true"
      >
        <VIcon>settings</VIcon>
      </VBtn>

      <ContextHelp />

      <LocalSwitch />

      <VMenu
        offset-y
        open-on-hover
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            v-bind="attrs"
            text
            :elevation="0"
            v-on="on"
          >
            <VAvatar
              v-if="account.profile.photo"
              size="32"
            >
              <VImg
                :src="`/${account.profile.photo}`"
                :alt="account.title"
              />
            </VAvatar>
            <VIcon v-else>
              person
            </VIcon>
            <span class="account-title">{{ accountTitle }}</span>
            <VIcon>expand_more</VIcon>
          </VBtn>
        </template>

        <VList dense>
          <VListItem href="/training/sessions">
            <VListItemContent>
              <VListItemTitle>
                Старый личный кабинет
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VDivider />
          <VListItem :to="`/users/${account.id}`">
            <VListItemContent>
              <VListItemTitle>{{ $t('profiles.one') }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem
            v-if="account.profile.is_default_listener"
            link
          >
            <VListItemContent>
              <VListItemTitle @click="dialog=true">
                {{ $t('order_management.one') }}
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            :to="menuItem.to"
            :href="menuItem.href"
          >
            <VListItemContent>
              <VListItemTitle>{{ $t(menuItem.title) }}</VListItemTitle>
            </VListItemContent>
            <VListItemIcon>
              <VBadge
                :value="menuItem.counter"
                :content="menuItem.counter"
                inline
              />
            </VListItemIcon>
          </VListItem>
          <VDivider />
          <VListItem @click.prevent="logoutAccount">
            <VListItemContent>
              <VListItemTitle>{{ $t('anonymous_sign_out.one') }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VMenu>
    </VAppBar>

    <VNavigationDrawer
      v-if="isDevelopment"
      v-model="settings"
      fixed
      temporary
      hide-overlay
      right
    >
      <VToolbar>
        <VToolbarTitle>Настройки</VToolbarTitle>
        <VSpacer />
        <VBtn
          title="Свернуть"
          icon
          @click="settings = false"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </VToolbar>
      <VDivider />
      <VContainer>
        <VSwitch
          v-model="$vuetify.theme.dark"
          inset
          label="Тёмная тема"
        />
        <VSelect
          v-model="theme"
          :items="themes"
          item-text="localizedTitle"
          item-value="title"
          label="Тема оформления"
          dense
          outlined
          @change="setTheme"
        />
      </VContainer>
    </VNavigationDrawer>
    <VDialog
      v-model="dialog"
      max-width="400"
    >
      <VCard>
        <VForm
          method="POST"
          :action="'/users/'+ account.parent_id + '/login'"
          autocomplete="off"
        >
          <VCardTitle>
            {{ $t('$app.go_management') }}
          </VCardTitle>
          <VDivider />
          <VCardActions>
            <VSpacer />
            <VBtn
              color="primary"
              text
              @click="dialog = false"
            >
              {{ $t('no.one') }}
            </VBtn>
            <VBtn
              color="error"
              type="submit"
            >
              {{ $t('yes.one') }}
            </VBtn>
          </VCardActions>
        </VForm>
      </VCard>
    </VDialog>

    <VMain>
      <slot />
    </VMain>
    <UFooter />
  </Fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Fragment } from 'vue-fragment'

import logotype from '@/assets/images/uchi.svg'
import { getVendorLimits } from '@/api'
import { THEMES } from '@/utils/themes'
import { newMessagesCounter } from '@/utils/messagesCounter'
import UFooter from '@components/UFooter.vue'
import ContextHelp from '@/components/ContextHelp.vue'
import LocalSwitch from '@/components/LocalSwitch.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

class MenuItem {
  constructor ({
    name = '',
    title = '',
    icon = '',
    href = '',
    to = '',
    disabled = false,
    counter = 0
  }) {
    this.name = name
    this.title = title
    this.icon = icon
    this.href = href
    this.to = to
    this.disabled = disabled
    this.counter = counter
  }
}

export default {
  name: 'ULayoutMain',
  components: {
    Fragment,
    ContextHelp,
    LocalSwitch,
    UFooter
  },

  data () {
    return {
      logotype,
      limits: {},
      theme: '',
      settings: false,
      dialog: false,
      themes: Object.values(THEMES)
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      vendor: getters.VENDOR,
      countNewMessages: getters.COUNT_NEW_MESSAGES,
      countNewSessions: getters.COUNT_NEW_SESSIONS,
      countNewMeetings: getters.COUNT_NEW_MEETINGS
    }),

    menuItems () {
      const initialMenuItems = [
        new MenuItem({
          name: 'infobase',
          title: '$app.menuItem.infobase',
          icon: 'import_contacts',
          href: '/infobase',
          disabled: !this.limits.infobase_available
        }),

        new MenuItem({
          name: 'programs',
          title: 'sessions.other',
          icon: '$uchi_programs',
          to: '/sessions',
          counter: this.countNewSessions
        }),

        new MenuItem({
          name: 'messages',
          title: 'messages.other',
          icon: '$uchi_messages',
          to: '/messages',
          disabled: this.limits.messages_disabled,
          counter: this.countNewMessages
        }),

        new MenuItem({
          name: 'marks',
          title: 'marks.other',
          icon: '$uchi_marks',
          to: '/marks'
        }),

        new MenuItem({
          name: 'students_record_book',
          title: 'students_record_book.one',
          icon: '$uchi_history',
          to: '/history'
        }),

        new MenuItem({
          name: 'meetings',
          title: 'meetings.two',
          icon: '$uchi_meetings',
          to: '/meetings',
          disabled: !this.limits.meetings_available,
          counter: this.countNewMeetings
        }),

        new MenuItem({
          name: 'groups',
          title: '$app.menuItem.groups',
          icon: '$uchi_groups',
          to: '/groups',
          disabled: !this.limits.groups_writs_available
        })
      ]

      return initialMenuItems.filter(item => !item.disabled)
    },

    accountTitle () {
      return this.account?.title
        .split(' ')
        .map((str, i) => i !== 0 ? `${str[0]}.` : str)
        .join(' ')
    },

    isDevelopment () {
      return process.env.NODE_ENV === 'development'
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      logoutAccount: actions.LOGOUT,
      getSessions: actions.GET_SESSIONS,
      getMeetings: actions.GET_MEETINGS
    }),

    setTheme (theme) {
      const _theme = THEMES[theme]
      if (!_theme) {
        return
      }

      this.$vuetify.theme.themes.light.background = _theme.background
      this.$vuetify.theme.themes.dark.background = _theme.background

      this.$vuetify.theme.themes.light.text = _theme.text
      this.$vuetify.theme.themes.dark.text = _theme.text
    },

    async register () {
      if (this.vendor && this.vendor.branding && this.vendor.branding.theme) {
        const theme = this.vendor.branding.theme
        this.setTheme(theme)
      }

      const { limits = {} } = await getVendorLimits(this.vendor.id)
      this.limits = limits

      if (!this.limits?.messages_disabled) {
        newMessagesCounter.start()
      }

      if (this.$route.name !== 'sessions') {
      /* Незаметно загрузить список сессий, чтобы показать счетчик */
        this.getSessions(false)
      }

      if (this.$route.name !== 'meetings' && this.limits.meetings_available) {
        /* Незаметно загрузить список мероприятий, чтобы показать счетчик */
        this.getMeetings(false)
      }
    },

    unregister () {
      if (!this.limits?.messages_disabled) {
        newMessagesCounter.stop()
      }
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .main-menu {
    background-image: linear-gradient(to bottom, var(--v-background-base), var(--v-background-lighten2));

    .v-btn.v-size--default {
      height: auto;
      color: var(--v-text-base);
      background-color: transparent !important;
      display: block;
      width: 100%;
      min-width: 100%;
      box-shadow: none;
      white-space: normal;
      text-align: center;
      padding: ($spacer * 4) $spacer;
      text-transform: none;
      letter-spacing: normal;
    }

    .v-btn__content {
      flex-direction: column;
    }

    .v-icon,
    .v-icon__svg {
      font-size: 32px;
      width: 32px;
      height: 32px;
    }
  }

  .theme--light {
    &.app-bar.v-toolbar.v-sheet {
      background-color: white;
    }
  }

  .app-bar__logo {
    display: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: var(--app-navigation-width);
      height: var(--app-bar-height);
      margin-left: -1rem;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    > * {
      height: 100%;
    }
  }

  .v-toolbar__title {
    font-size: map-deep-get($headings, "h4", "size");

    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .account-title {
    display: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: inline;
      text-transform: none;
      letter-spacing: normal;
      margin-right: $spacer;
      margin-left: $spacer;
    }
  }

  .translate-flag {
    width: 1.5rem;
    height: auto;
  }
}
</style>
